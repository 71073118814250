import React, { useState, useEffect } from "react";
import axios from "axios";

import BirdList from "../birds/BirdList";
// import { notifyBirds } from "../utils/SSE";
import { SSE_URL } from "../utils/constants";

import "./OldBirds.css";


//Notifiy of new birds on old screens
// notifyBirds();

export default function HallOfFame(props){
	let [birds, setBirds] = useState([])
	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await axios.get(`${SSE_URL}/migration/hall-of-fame.php`);
				setBirds(result.data.items);
			} catch (err){
				console.log("No Birds");
			}
		};

		fetchData();
	}, []);

	return (
		<section className="old-birds">
			<header className="old-info-header">
				<h2>Hall Of Fame</h2>
				<p>These are some of the best photos, unique photos, or interesting birds that have shown up to the feeder over time</p>
			</header>
			<BirdList birds={birds} />
		</section>
	);
}

HallOfFame.displayName = "HallOfFame";