import React, { useState, useEffect } from "react";
import { format } from "date-fns";

import BirdList from  "../birds/BirdList";
import DeadBirds from "../birds/DeadBirds";
import { SERVER_DATE } from "../utils/constants";

import { BirdMessage, watcher } from "../utils/SSE";
import { Notify } from "../utils/Notify";
import "./MainBirds.css";

// For whatever reason, we can't connect
// let birds = notifyBirds();//new EventSource(`${SSE_URL}/birds/`);
const NOW = format(new Date(), SERVER_DATE);

export default function MainBirds(props){
	const [liveBirds, setBirds] = useState([])
		// , [babyBirds, setBabyBirds] = useState()
		, [ isFocusing, setFocus] = useState(false)
		, classes = isFocusing ? "add-bird live-birds" : "live-birds"
		;

	useEffect(() => {
		watcher.addEventListener("message", (event) => {
			let data = JSON.parse(event.data);

			if(!/focusing|bird|photo/gi.test(data.type)){ return; }

			let birdType = data.data?.type || "";
			if(/focusing/gi.test(birdType)){
				setFocus(true);
				try {
					Notify(BirdMessage);
				} catch(err){}
			} else if(/photo/gi.test(birdType)){
				let [location, fileName] = data.data.src.split("/")
					, currentDate = null

				try{
					currentDate = new Date(data.data.timestamp);
				} catch (err){
					currentDate = new Date().toISOString();
				}

				// birdList.unshift();
				// setBabyBirds({ ...data.data, location, fileName });
				setBirds((current) => {
					return [{ ...data.data, location, fileName, date:currentDate, }, ...current];
				});
				setFocus(false);
			}
		});	//end birds.onmessage()
		watcher.addEventListener("error", (err) => {
			console.log("Error with connection");
			console.error(err);
		});	//end birds.onerror()
	}, []);

	// useEffect(() => {
	// 	setBirds((current)[babyBirds, ...liveBirds]);
	// }, [babyBirds])

	return (
		<article className="bird-list">
			<section className={classes}>
				{isFocusing ? <div className="empty-bird"><i className="spinner" /></div> : null}
				<BirdList birds={liveBirds} />
			</section>
			<section className="old-birds">
				<DeadBirds date={NOW} />
			</section>
		</article>
	);
}

MainBirds.displayName = "MainBirds";