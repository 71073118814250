/**
 * Bird List
 * Opens a Lightbox for the bird list
 */
import React, { useState } from "react";
import Lightbox from 'react-image-lightbox';

import Bird, { TimeFormat, buildURL } from "./Bird";

export default function BirdList({ birds }){
	let [isOpen, setOpen] = useState(false)
		, [photoIndex, setIndex] = useState(0)
		, sources = birds.map(v => buildURL(v))
		, captions = birds.map(v => <TimeFormat key={v.date} {...v} />)
		;

	if(birds.length === 0){ return null; }

	return (
		<article className="all-birds">
			<header>Total Birds Today: {birds.length}</header>
			{isOpen && <Lightbox
							mainSrc={sources[photoIndex]}
							nextSrc={sources[(photoIndex + 1) % sources.length]}
							prevSrc={sources[(photoIndex + sources.length - 1) % sources.length]}
							imageTitle={captions[photoIndex]}
							onCloseRequest={() => setOpen(false)}
							onMovePrevRequest={() =>{
								setIndex((photoIndex + sources.length - 1) % sources.length);
							}}
							onMoveNextRequest={() =>{
								setIndex((photoIndex + 1) % sources.length);
							}}
						/>}
			<section className="dead-birds">
				{birds.map((v,idx) => <Bird key={v.date} {...v} onClick={() => {
					setIndex(idx);
					setOpen(!isOpen);
				}} />)}
			</section>
		</article>
	);
}

BirdList.defaultProps = { birds:[] };
BirdList.displayName = "BirdList";