import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import WeatherHeader from "./weather/WeatherHeader";
import { UI_DATE } from "./utils/constants";

import "./MainHeader.css";

let now = new Date()
	, pretty = format(now, UI_DATE)
	;

			// <img src="/android-chrome-192x192.png" alt="Feeder Logo" />
export default function MainHeader(props){
	return (
		<header className="main-header">
			<h1>Bird Feeder View</h1>
			<WeatherHeader watcher={props.watcher} />
			<p>Real-time view of our raspberry-pi Bird-Feeder.  <Link to="/">The home page</Link> page will show photos for today <time date={now.toISOString()}>{pretty}</time>.  If you'd like to see other days, check out <a href="#bird-feet">the footer</a> below.  Check out the <a href="hof" title="Hall Of Fame">Hall Of Fame</a> for some of the best photos taken from this bird feeder!</p>
		</header>
	);
}

MainHeader.displayName = "MainHeader";