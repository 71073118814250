import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import "./MainFooter.css";

const YEAR = format(new Date(), "yyyy");

export default function MainFooter(props){
	/* eslint-disable */
	return (
		<footer className="main-footer" id="bird-feet">
			© 2020 - {YEAR} <a href="https://steve.deadlycomputer.com/" target="_BLANK" rel="noopener" className="sdc">stephen giorgi</a>
			<Link to="/about/">About this Project</Link>
		</footer>
	);
	/* eslint-enable */
}
MainFooter.displayName = "MainFooter";
