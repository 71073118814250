import React from "react";

import "./About.css";

const DEPS = {
	"react":               "https://facebook.github.io/react/",
	"react-router":        "https://github.com/ReactTraining/react-router",
	"axios":               "https://github.com/mzabriskie/axios",
	"react-image-lightbox":"https://github.com/frontend-collective/react-image-lightbox",
	// "react-Helmet":        "https://github.com/nfl/react-helmet",
	"date-fns":"https://date-fns.org/",
	"webpack":             "https://webpack.github.io/",
	"babel":               "https://babeljs.io/",
}

let t = null
	, $tech = []
	;

for(t in DEPS){
	$tech.push(
		<li key     = {t}>
			<a href    = {DEPS[t]}
				title   = {t}
				rel="noopener noreferrer"
				target  = "_BLANK">{t}</a>
		</li>
	);
}



export default function About(props){
	return (
		<article className="about-section">
			<header>
				<h2>About It all</h2>
				<p>This project is powered by a <a href="https://www.raspberrypi.org/products/raspberry-pi-zero/" rel="noopener noreferrer" target="_BLANK">raspberry-pi zero</a>.  The website is made possible by the following</p>
			</header>
			<section className="things">
				<ul>
					<li>Cardinal by Matt Steele from the Noun Project</li>
					<li>Cardinal by Matthew Davis from the Noun Project</li>
				</ul>
				<ul>
					<li><a href="https://realfavicongenerator.net/" rel="noopener noreferrer" target="_BLANK">Real Favicon Generator</a></li>
					<li><a href="https://colorhunt.co/palettes/dark" rel="noopener noreferrer" target="_BLANK">Color Hunt</a></li>
					<li><a href="https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/" rel="noopener noreferrer" target="_BLANK">Responsive Design</a></li>
					<li><a href="https://cssgradient.io/" rel="noopener noreferrer" target="_BLANK">CSS Gradient Generator</a></li>
				</ul>
			</section>
			<section className="dependencies"><ul>{$tech}</ul></section>
		</article>
	);
}

About.displayName = "About";