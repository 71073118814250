import React from "react";
import { useParams } from "react-router-dom";
import { parseISO, format } from "date-fns";

import DeadBirds from "../birds/DeadBirds";
// import { notifyBirds } from "../utils/SSE";
import { UI_DATE } from "../utils/constants";

import "./OldBirds.css";


//Notifiy of new birds on old screens
// notifyBirds();

export default function OldBirds(props){
	let { date } = useParams();

	return (
		<section className="old-birds">
			<header className="old-info-header">
				<h2>{format(parseISO(date), UI_DATE)}</h2>
				<p>This is a historical view of the bird photos taken on: {format(parseISO(date), UI_DATE)}</p>
			</header>
			<DeadBirds date={date} />
		</section>
	);
}

OldBirds.displayName = "OldBirds";