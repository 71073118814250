import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import MainBirds from "./routes/MainBirds";
import OldBirds from "./routes/OldBirds";
import HallOfFame from "./routes/HallOfFame";
import About from "./routes/About";
import MainHeader from "./MainHeader";
import Calendar from "./Calendar";
import MainFooter from "./MainFooter";
import { watcher } from "./utils/SSE";

import './App.css';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


function App() {
	return (
		<Router>
			<div className="App">
				<MainHeader watcher={watcher} />
				<main className="body-content">
					<Switch>
						<Route path="/" exact>
							<MainBirds />
						</Route>
						<Route path="/date/:date/">
							<OldBirds />
						</Route>
						<Route path="/hof/">
							<HallOfFame />
						</Route>
						<Route path="/about/">
							<About />
						</Route>
					</Switch>
				</main>
				<Calendar />
				<MainFooter />
			</div>
		</Router>
	);
}

export default App;
