/**
 * Notify the user
 */

export function Notify(obj){
	if(!Notification){ return Promise.reject({ allowed:false }); }

	if(/default/gi.test(Notification.permission)){
		return Notification.requestPermission()
				.then(resp => {
					if(/granted/gi.test(resp)){
						return Notify(obj);
					}
				});
	}

	if(!/granted/gi.test(Notification.permission)){ return Promise.reject({ allowed:false }); }

	try {
		new Notification(obj.label, obj);
	} catch (err){
		return Promise.reject({ allowed:false, sent:false });
	}

	return Promise.resolve({ allowed:true, sent: true });
}
