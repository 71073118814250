import React, { useState, useEffect } from "react";
import axios from "axios";

import BirdList from "./BirdList";
import { SSE_URL } from "../utils/constants";

import "./DeadBirds.css";

export default function DeadBirds({ date }){
	let [oldBirds, setOld] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await axios.get(`${SSE_URL}/migration/historic.php?when=${date}`);
				setOld(result.data.items.reverse());
			} catch (err){
				console.log("No Birds");
			}
		};

		fetchData();
	}, [date]);

	if(oldBirds.length === 0){
		/* eslint-disable */
		return (
			<article className="all-birds">
				<section className="dead-birds really-dead-birds">
					<p className="desc">Unfortunately, no birds have come for a snack yet today.  Perhaps the feeder needs to be refilled?  You could admire some of the beautiful visitors we had in days past.  Or you could check out some of my other <a href="https://steve.deadlycomputer.com/album/wildlife/" title="Wildlife Photos!" target="_BLANK">wildlife photos</a>.  There's some good ones in there, birds too!</p>
					<img className="logo" src={`${process.env.PUBLIC_URL}/cardinal-logo.svg`} alt="Cardinal Logo" />
				</section>
			</article>
		);
		/* eslint-enable */
	}

	return <BirdList birds={oldBirds} />;
}

DeadBirds.displayName = "DeadBirds";
DeadBirds.defaultProps = {
	date:"",
};
