import React, { useEffect, useState } from 'react';
import { watcher } from "../utils/SSE";
import { formatDistance } from "date-fns";

import "./WeatherHeader.css";

const MILI = 1000;
	// , temps = weatherWatcher();

export default function WeatherHeader(){
	let [data, setWeather] = useState({ weather: {}})
		, [hasError, setError] = useState(false)
		, [uptime, setUp] = useState(0)
		, errorClass = hasError ? "sse-error" : ""
		, timeStr = null, dateStr = null
		;

	useEffect(() => {
		watcher.addEventListener("message", (event) => {
			let dt = JSON.parse(event.data);

			if(dt.type !== "weather"){ return; }

			setWeather(dt.data);

			try {
				setUp(formatDistance(0, (dt.data.system.uptime * MILI)));
			} catch (err){}
			setError(false);
		});
		watcher.addEventListener("error",(err) => {
			setError(true);
		});
	}, []);

	if(data.timestamp){
		[dateStr, timeStr] = data.timestamp.split("@")
	}

	return (
		<aside className={`weather-header ${errorClass}`}>
			<div className="server-info">
				<span>Server Info</span>
				<table>
					<tbody>
						<tr>
							<td className="bold">Core Temp:</td>
							<td>{data?.system?.temperature}°C</td>
						</tr>
						<tr>
							<td className="bold">Date:</td>
							<td><time dateTime={data?.timestamp}>{dateStr}</time></td>
						</tr>
						<tr>
							<td className="bold">Time:</td>
							<td><time>{timeStr}</time></td>
						</tr>
						<tr>
							<td className="bold">Uptime:</td>
							<td>{uptime}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="outside-temp">
				<span>Current Weather Conditions</span>
				<table>
					<tbody>
						<tr>
							<td className="bold">Temp:</td>
							<td>
								<span className="degree-f">{data?.weather?.temperature?.f}°F</span>
								<span className="degree-c">{data?.weather?.temperature?.c}°C</span>
							</td>
						</tr>
						<tr>
							<td className="bold">Dew Point:</td>
							<td>
								<span className="degree-f">{data?.weather?.dewPoint?.f}°F</span>
								<span className="degree-c">{data?.weather?.dewPoint?.c}°C</span>
							</td>
						</tr>
						<tr>
							<td className="bold">Humidity:</td>
							<td>
								<span className="humidity">{data?.weather?.humidity}%</span>
							</td>
						</tr>
						<tr>
							<td className="bold">UV Index:</td>
							<td>
								<span className="UV Index">{data?.weather?.uv?.uvIndex}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</aside>
	);
}

WeatherHeader.displayName = "WeatherHeader";
