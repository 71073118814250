import React from "react";
import { format, parseISO } from "date-fns";

import { SSE_URL } from "../utils/constants";
import "./Bird.css";

export default function Bird(props){
	return (
		<article className="blue-jay">
			<header><TimeFormat {...props} /></header>
			<figure className="bird" onClick={props.onClick} title="Zoom In!">
				<img className="bird-image" src={buildURL(props)} alt={`Bird @${props.prettyTime}`} loading="lazy" />
			</figure>
		</article>
	);
}

Bird.displayName = "Bird";

export function TimeFormat(props){
	let prettyTime = props.date;
	try {
		prettyTime = format(parseISO(props.date), "pp");
	} catch (err){
		try {
			prettyTime = format(props.date, "pp");
		} catch (err2){
			console.error(err2);
			console.error("Could not format date:", props.date);
		}
	}
	return <time className="time" dateTime={props.date}>{prettyTime}</time>;
}

export function buildURL(props){
	return `${SSE_URL}/photos/${props.location}/${props.fileName}`;
}
