import React, { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";
import { Link } from "react-router-dom";
import axios from "axios";

import { SSE_URL, UI_DATE } from "./utils/constants";

import "./Calendar.css";

export default function Calendar({ date }){
	let [oldBirds, setOld] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await axios.get(`${SSE_URL}/migration/historic.php?when=all`);
				setOld(result.data.items.reverse());
			} catch (e){
				console.error("Problem with data");
			}
		};
		fetchData();
	}, [date]);

	return (
		<section className="calendar-birds">
			<header>
				<p>Below are links to each day in which our bird-feeder took a photo.  You can click through each one and see the photos taken that day.</p>
			</header>
			<ul className="calendar-list">
				{oldBirds.map((v) => {
					return (
						<li key={v.day}>
							<Link to={`/date/${v.day}/`}>{format(parseISO(v.day), UI_DATE)}</Link>
							<span className="total-photos">({v.totalPhotos} Birds!)</span>
						</li>
					);
				})}
			</ul>
		</section>
	);
}

Calendar.displayName = "Calendar";
Calendar.defaultProps = {
	date:"",
};
