/**
 * Bird SSE
 */
import { SSE_URL } from "./constants";
import { Notify } from "./Notify";

export function birdWatcher(){
	return new EventSource(`${SSE_URL}/info/birds/`);
}

export function weatherWatcher(){
	return new EventSource(`${SSE_URL}/info/current-weather/`);
}

export const BirdMessage = {
	label:"New Bird",
	icon:"/android-chrome-192x192.png",
	body:"A bird has landed on the feeder looking for a snack.  Want to see who it is?!",
	// badge:"/android-chrome-192x192.png",
	// image:"/android-chrome-192x192.png",
	// Don't want it making any vibrations
	vibrate:false,
};

export const watcher = weatherWatcher();

export function notifyBirds(){
	// let birds = birdWatcher();

	watcher.addEventListener("message", function(evt){
		let data = JSON.parse(evt.data);

		if(/focusing|bird/gi.test(data.type)){ return; }

		if(/focusing/gi.test(data.type)){
			Notify(BirdMessage);
		}
	});	//end birds.onmessage()

	return watcher;
}